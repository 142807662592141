<template>
    <smart-tagz autosuggest
                inputPlaceholder="Select Tags..."
                :maxTags="maxTags ? maxTags : 5"
                :sources="sources"
                :defaultTags="modelValue"
                :on-changed="updateTags"
                @click.prevent
    />
</template>

<script>
import {SmartTagz} from "smart-tagz";
import "smart-tagz/dist/smart-tagz.css";

export default {
    name: "SmartTagsComponent",
    props: ['sources', 'maxTags', 'modelValue'],
    emits: ['update:modelValue'],
    methods: {
        updateTags(tags) {
            this.$emit('update:modelValue', tags)
        }
    },
    components: {
        SmartTagz
    },
}
</script>

<style scoped>

</style>