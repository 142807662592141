<template>
    <div class="modal fade invite-people show" tabindex="-1" role="dialog"
         style="display: block; padding-right: 17px;">
        <div class="modal-dialog">
            <div class="modal-content">
                <button @click="hideComponent" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body">
                    <h3>{{ title }}</h3>

                    <select v-model="select" @change="selected" class="custom-select form-control">
                        <option value="">Select...</option>
                        <option v-for="field in fields" :value="field">{{ field?.name || field?.value || field?.label || 'unknown' }}</option>
                    </select>

                    <button @click="hideComponent" class="btn btn-secondary float-right mt-2">CANCEL</button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalDropdownComponent",
    data() {
        return {
            select: ''
        }
    },
    props: ['fields', 'title'],
    emits: ['hideComponent', 'selected'],
    methods: {
        hideComponent() {
            this.$emit('hideComponent')
        },
        selected() {
            if (this.select) {
                this.$emit('selected', this.select)
                this.$emit('hideComponent')
            }
        }
    }
}
</script>

<style scoped>

</style>